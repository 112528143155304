// src/components/TiltNotification.js
import React, { useEffect, useState } from 'react';
import './TiltNotification.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobileAlt } from '@fortawesome/free-solid-svg-icons';

const TiltNotification = () => {
  const [isMobilePortrait, setIsMobilePortrait] = useState(false);

  useEffect(() => {
    const updateOrientation = () => {
      const isPortrait = window.innerHeight > window.innerWidth;
      const isMobile = window.innerWidth <= 768;
      setIsMobilePortrait(isMobile && isPortrait);
    };

    // Run the check initially and whenever the window resizes
    updateOrientation();
    window.addEventListener('resize', updateOrientation);
    return () => window.removeEventListener('resize', updateOrientation);
  }, []);

  // Return nothing if it's not mobile portrait mode
  if (!isMobilePortrait) return null;

  return (
    <div className="tilt-notification">
      <div className="icon-container">
        <FontAwesomeIcon icon={faMobileAlt} className="phone-icon" />
        <div className="rotate-arrow">↻</div>
      </div>
      <p>Please rotate your phone for a better experience!</p>
    </div>
  );
};

export default TiltNotification;
