import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Updated to use useNavigate
import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL;

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  
  const navigate = useNavigate(); // Replaced useHistory with useNavigate
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = window.location.pathname.split('/').pop(); // Assuming token is in the URL
      await axios.post(`${API_URL}/api/reset-password/${token}`, { newPassword });
      setMessage('Password reset successfully. Redirecting to login...');
      setTimeout(() => navigate('/login'), 3000); // Redirect after 3 seconds
    } catch (err) {
      setError('Failed to reset password. Please try again.');
    }
  };

  return (
    <div>
      <h2>Reset Password</h2>
      <form onSubmit={handleSubmit}>
        <input 
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          placeholder="Enter new password"
          required
        />
        <button type="submit">Reset Password</button>
      </form>
      {message && <p>{message}</p>}
      {error && <p>{error}</p>}
    </div>
  );
};

export default ResetPassword;
