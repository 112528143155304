import React, { useState, useEffect } from 'react';
import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL;

const Points = ({ refresh }) => {
  const [points, setPoints] = useState(0);

  useEffect(() => {
    const fetchPoints = async () => {
      try {
        console.log('Fetching points...');
        const res = await axios.get(`${API_URL}/api/get-points`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });
        console.log('Response from API:', res.data);
        setPoints(res.data.points);
      } catch (err) {
        console.error('Failed to fetch points:', err);
      }
    };

    fetchPoints();
  }, [refresh]);

  return (
    <div>
      <h3>Συνολικοί Πόντοι: {points}</h3>
    </div>
  );
};

export default Points;
