import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Position from './Position';
import Roster from './Roster';
import './Field.css';
import { jwtDecode } from 'jwt-decode';  // Adjust this import statement
const API_URL = process.env.REACT_APP_API_URL;


const formations = {
  '4-3-3': [
    ['LW', 'ST', 'RW'],
    ['CM1', 'CM2', 'CM3'],
    ['LB', 'CB1', 'CB2', 'RB'],
    ['GK'],
  ],
  '4-4-2': [
    ['ST1', 'ST2'],
    ['LM', 'CM1', 'CM2', 'RM'],
    ['LB', 'CB1', 'CB2', 'RB'],
    ['GK'],
  ],
  '3-4-3': [
    ['LW', 'ST', 'RW'],
    ['LM', 'CM1', 'CM2', 'RM'],
    ['CB1', 'CB2', 'CB3'],
    ['GK'],
  ],
  '5-3-2': [
    ['ST1', 'ST2'],
    ['CM1', 'CM2', 'CM3'],
    ['LWB', 'CB1', 'CB2', 'CB3', 'RWB'],
    ['GK'],
  ],
};

const arisPlayers = [
  { id: 1, name: 'Julián Cuesta', position: 'GK', photo: require('../images/players/cuesta.jpg'), lastGameRating: 7.2, averageRating: 6.9 },
  { id: 2, name: 'Κωνσταντίνος Κυριαζής', position: 'GK', photo: require('../images/players/kyriazis.jpg'), lastGameRating: null, averageRating: null },
  { id: 3, name: 'Filip Sidklev', position: 'GK', photo: require('../images/players/Sidklev.jpg'), lastGameRating: null, averageRating: null },
  { id: 4, name: 'Fabiano', position: 'CB', photo: require('../images/players/fabiano.jpg'), lastGameRating: null, averageRating: null },
  { id: 5, name: 'Jakub Brabec', position: 'CB', photo: require('../images/players/brabec.jpg'), lastGameRating: 6.7, averageRating: 6.8 },
  { id: 6, name: 'Fran Vélez', position: 'CB', photo: require('../images/players/velez.jpg'), lastGameRating: 6.4, averageRating: 6.7 },
  { id: 7, name: 'Lindsay Rose', position: 'CB', photo: require('../images/players/rose.jpg'), lastGameRating: null, averageRating: null },
  { id: 8, name: 'Hugo Mallo', position: 'RB', photo: require('../images/players/mallo.jpg'), lastGameRating: 7.5, averageRating: 7.2 },
  { id: 9, name: 'Martín Montoya', position: 'RB', photo: require('../images/players/montoya.jpg'), lastGameRating: 7.5, averageRating: 7.2 },
  { id: 10, name: 'Valentino Fattore', position: 'RB', photo: require('../images/players/fattore.jpg'), lastGameRating: null, averageRating: null },
  { id: 11, name: 'Martin Frydek', position: 'LB', photo: require('../images/players/frydek.jpg'), lastGameRating: 7.2, averageRating: 6.9 },
  { id: 12, name: 'Juankar', position: 'LB', photo: require('../images/players/juankar.jpg'), lastGameRating: 7.2, averageRating: 6.9 },
  { id: 13, name: 'Jean Jules', position: 'CM', photo: require('../images/players/jules.jpg'), lastGameRating: null, averageRating: null },
  { id: 14, name: 'Monchu', position: 'CM', photo: require('../images/players/monchu.jpg'), lastGameRating: 7.1, averageRating: 7.0 },
  { id: 15, name: 'José Cifuentes', position: 'CM', photo: require('../images/players/cifuentes.jpg'), lastGameRating: 7.1, averageRating: 7.0 },
  { id: 16, name: 'Vladimír Darida', position: 'CM', photo: require('../images/players/darida.jpg'), lastGameRating: 7.0, averageRating: 7.0 },
  { id: 17, name: 'Manu García', position: 'CM', photo: require('../images/players/garcia.jpg'), lastGameRating: null, averageRating: null },
  { id: 18, name: 'Γιάννης Φετφατζίδης', position: 'RW', photo: require('../images/players/fetfatzidis.jpg'), lastGameRating: 6.6, averageRating: 6.7 },
  { id: 19, name: 'Shapi Suleymanov', position: 'RW', photo: require('../images/players/suleymanov.jpg'), lastGameRating: 7.0, averageRating: 7.0 },
  { id: 20, name: 'Pione Sisto', position: 'LW', photo: require('../images/players/sisto.jpg'), lastGameRating: null, averageRating: null },
  { id: 21, name: 'Álvaro Zamora', position: 'LW', photo: require('../images/players/zamora.jpg'), lastGameRating: 7.2, averageRating: 7.0 },
  { id: 22, name: 'Kike Saverio', position: 'LW', photo: require('../images/players/saverio.jpg'), lastGameRating: null, averageRating: null },
  { id: 23, name: 'Clayton Diandy', position: 'LW', photo: require('../images/players/diandy.jpg'), lastGameRating: null, averageRating: null },
  { id: 24, name: 'Loren Morón', position: 'ST', photo: require('../images/players/moron.jpg'), lastGameRating: 6.3, averageRating: 6.5 },
  { id: 25, name: 'Θεόδωρος-Γεώργιος Αγοραστός', position: 'ST', photo: require('../images/players/agorastos.jpg'), lastGameRating: null, averageRating: null },
  { id: 26, name: 'Robin Quaison', position: 'ST', photo: require('../images/players/quaison.jpg'), lastGameRating: null, averageRating: null },
];

const Field = () => {
  const [formation, setFormation] = useState('4-3-3');
  const [assignedPlayers, setAssignedPlayers] = useState({});
  const [roster, setRoster] = useState(arisPlayers);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [currentMatchId, setCurrentMatchId] = useState(null);
  const [showFullPageMessage, setShowFullPageMessage] = useState(false);

  // Fetch the current match ID on mount
  useEffect(() => {
    const fetchCurrentMatch = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/get-current-match`);
        const matchData = response.data;
        if (matchData && matchData.matchId) {
          setCurrentMatchId(matchData.matchId); // Set the match ID from the response
        } else {
          console.error('No match data found.');
        }
      } catch (error) {
        console.error('Error fetching current match:', error);
      }
    };
    fetchCurrentMatch();
  }, []);

  useEffect(() => {
    const fetchLineup = async () => {
      try {
        const token = localStorage.getItem('token');
  
        if (!token) {
          console.error('No token found in local storage.');
          return;
        }
  
        const decodedToken = jwtDecode(token);
        const userId = decodedToken.id;
  
        if (!currentMatchId) {
          console.error('No current match ID available.');
          return;
        }
  
        console.log('Fetching lineup for match:', currentMatchId, 'and user:', userId);
  
        const res = await axios.get(`${API_URL}/api/get-lineup/${currentMatchId}/${userId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
  
        if (res.data.lineup) {
          console.log('Fetched lineup data:', res.data.lineup);
  
          const fetchedLineup = res.data.lineup.reduce((acc, { position, player }) => {
            if (player && position) {
              acc[position] = player;
            } else {
              console.error(`Invalid player or position: ${position}, ${player}`);
            }
            return acc;
          }, {});
  
          console.log('Mapped lineup:', fetchedLineup);
  
          setAssignedPlayers(fetchedLineup);
  
          const updatedRoster = arisPlayers.filter(
            (player) => !Object.values(fetchedLineup).some((assignedPlayer) => assignedPlayer?.name === player.name)
          );
          setRoster(updatedRoster);
          setIsSubmitted(true);
        } else {
          console.error('No lineup found in the response.');
        }
      } catch (err) {
        console.error('Failed to fetch lineup:', err.response?.data || err.message);
      }
    };
  
    if (currentMatchId) {
      fetchLineup();
    }
  }, [currentMatchId]);
  



  const handleDrop = (item, position) => {
    if (isSubmitted) return;

    // Check if a player is already assigned to this position
    const currentPlayer = assignedPlayers[position];

    if (currentPlayer) {
      setRoster((prevRoster) => {
        const updatedRoster = [...prevRoster, currentPlayer];
        return updatedRoster.sort((a, b) => a.id - b.id);
      });
    }



    // Ensure the player being assigned has all the necessary details
    const droppedPlayer = roster.find((player) => player.name === item.name);

    if (!droppedPlayer) {
      console.error(`Player ${item.name} not found in roster.`);
      return;
    }

    setAssignedPlayers((prev) => ({
      ...prev,
      [position]: {
        id: droppedPlayer.id,
        name: droppedPlayer.name,
        realPosition: droppedPlayer.position,
        photo: droppedPlayer.photo,
        lastGameRating: droppedPlayer.lastGameRating,
        averageRating: droppedPlayer.averageRating,
      },
    }));

    // Remove the player from the roster
    setRoster((prevRoster) => prevRoster.filter((player) => player.name !== item.name));
  };

  const handleRemove = (playerName, position) => {
    // Remove the condition for `isSubmitted` if you want to allow removal after submission
    console.log(`Attempting to remove player: ${playerName} from position: ${position}`);
    
    // Remove the player from the assigned position
    setAssignedPlayers((prev) => {
      if (!prev[position]) {
        console.log(`Player not found in position: ${position}`);
        return prev; // Ensure player exists in the position
      }
  
      const newAssigned = { ...prev };
      delete newAssigned[position]; // Remove player from the specific position
      
      console.log("Assigned players after removal:", newAssigned);
      return newAssigned;
    });
  
    // Add the player back to the roster if not already present
    setRoster((prevRoster) => {
      const player = arisPlayers.find((p) => p.name === playerName);
      
      if (!player) {
        console.log(`Player ${playerName} not found in Aris player list.`);
        return prevRoster;
      }
      
      // If the player is already in the roster, don't add them again
      if (prevRoster.some((p) => p.id === player.id)) {
        console.log(`Player ${playerName} is already in the roster.`);
        return prevRoster;
      }
  
      const updatedRoster = [...prevRoster, player].sort((a, b) => a.id - b.id);
      console.log("Updated roster after adding player back:", updatedRoster);
      return updatedRoster;
    });
  };
  
  

  const handlePlayerDropToRoster = (playerName) => {
    const position = Object.keys(assignedPlayers).find((key) => assignedPlayers[key].name === playerName);
    handleRemove(playerName, position);
  };

  const renderFormation = (formation) => {
    return formations[formation].map((row, rowIndex) => (
      <div key={rowIndex} className={`row row-${rowIndex}`}>
        {row.map((position) => (
          <Position
            key={position}
            position={position}
            accept="PLAYER"
            onDrop={handleDrop}
            player={assignedPlayers[position]} // Make sure this fetches the correct player
            onRemove={handleRemove}
          />
        ))}
      </div>
    ));
  };

  const handleSubmit = async () => {
    if (!isAllPositionsFilled()) {
      console.error('Not all positions are filled.');
      return;
    }

    try {
      const token = localStorage.getItem('token');
      const decodedToken = jwtDecode(token);
      const userId = decodedToken.id;

      if (!currentMatchId) {
        console.error('No current match available.');
        return;
      }

      const formattedLineup = Object.keys(assignedPlayers).map((position) => {
        const player = assignedPlayers[position];

        if (!player || !player.id || !player.name || !player.realPosition) {
          console.error(`Player at position ${position} is missing required fields.`);
          return null;
        }

        return {
          position: position,
          player: {
            id: player.id,
            name: player.name,
            realPosition: player.realPosition,
            photo: player.photo,
            lastGameRating: player.lastGameRating,
            averageRating: player.averageRating,
          },
        };
      }).filter(item => item !== null);  // Filter out any invalid entries

      if (formattedLineup.length !== 11) {
        console.error('Invalid lineup. Not all positions have been filled.');
        return;
      }

      console.log('Submitting lineup:', {
        userId, 
        matchId: currentMatchId, 
        lineup: formattedLineup,
      });

      const response = await axios.post(
        `${API_URL}/api/save-lineup`,
        { userId, matchId: currentMatchId, lineup: formattedLineup },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.status === 200) {
        setIsSubmitted(true);
        setSuccessMessage('Η ενδεκάδα υποβλήθηκε επιτυχώς.');
        setShowFullPageMessage(true);
      } else {
        console.error('Failed to submit lineup:', response.data);
      }
    } catch (err) {
      console.error('Failed to save lineup:', err);
    }
  };

  const isAllPositionsFilled = () => {
    return formations[formation].flat().every((pos) => assignedPlayers[pos]);
  };

  return (
    <div className="field-container">
      {showFullPageMessage && (
        <div className="full-page-message">
          <div className="message-box">
            <p>{successMessage}</p>
            <button onClick={() => setShowFullPageMessage(false)} className="ok-button">OK!</button>
          </div>
        </div>
      )}



      <div className="sidebar">
        <label htmlFor="formation" className="formation-label">Επίλεξε Σύστημα:</label>
        <select
          id="formation"
          className="select-dropdown"
          value={formation}
          onChange={(e) => {
            setAssignedPlayers({});
            setRoster(arisPlayers); // Reset the roster to default players
            setFormation(e.target.value);
          }}
        >
          {Object.keys(formations).map((formationKey) => (
            <option key={formationKey} value={formationKey}>
              {formationKey}
            </option>
          ))}
        </select>
      </div>


      <div className="field">
        <div className="half-circle"></div>
        <div className="formation">
          {renderFormation(formation)}
        </div>
        <div className="penalty-box"></div>
        <div className="small-box"></div>
        <div className="small-half-circle"></div>
      </div>

      <div className="roster-and-button">
        <Roster roster={roster} onPlayerDrop={handlePlayerDropToRoster} />
        <button onClick={handleSubmit} disabled={!isAllPositionsFilled()} className="small-button">
          Υποβολή
        </button>
        {!isAllPositionsFilled() && <p>Παρακαλώ επιλέξτε 11 παίκτες και πατήστε "Υποβολή"!.</p>}
      </div>
    </div>
  );
};

export default Field;
