import React from 'react';
import { useDrop } from 'react-dnd';
import { Tooltip } from 'react-tooltip';

const Position = ({ position, player, onDrop, onRemove, isSubmitted }) => {
  const [, drop] = useDrop(() => ({
    accept: 'PLAYER',
    drop: (item) => {
      // Allow drop only if lineup is not submitted
      if (!isSubmitted) {
        onDrop(item, position);
      }
    },
  }));

  return (
    <div ref={drop} className="position" style={{ position: 'relative' }}>
      {player ? (
        <>
          <div className="position-content" style={{ position: 'relative', textAlign: 'center' }}>
            <img
              src={player.photo}
              alt={player.name}
              className="player-image"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                borderRadius: '5px',
              }}
              data-tooltip-id={`player-${player.name}`}
            />
            <Tooltip id={`player-${player.name}`} />
            <button
              className="remove-button"
              onClick={() => onRemove(player.name, position)}
              disabled={isSubmitted} // Disable the button if the lineup is submitted
              style={{
                position: 'absolute',
                top: '-10px',
                right: '-10px',
                backgroundColor: isSubmitted ? 'grey' : 'red', // Grey out the button if disabled
                color: 'white',
                border: 'none',
                borderRadius: '50%',
                width: '20px',
                height: '20px',
                cursor: isSubmitted ? 'not-allowed' : 'pointer', // Adjust cursor if disabled
                zIndex: 1,
              }}
            >
              X
            </button>
          </div>
          <div
            className="player-name"
            style={{
              marginTop: '5px',
              fontSize: '12px',
              color: 'gold',
              textAlign: 'center',
            }}
          >
            {player.name}
          </div>
        </>
      ) : (
        <span>{position}</span>
      )}
    </div>
  );
};

export default Position;
