import './UnderConstruction.css';

function UnderConstruction() {
  return (
    <div className="under-construction-container">
      <div className="overlay">
      <img src="/static/media/aris11.6b3731f8836b4a086b7a.png" alt="Logo" className="logo" />
        <h1>Under Construction</h1>
        <p>Our website is currently under construction. Stay tuned!</p>
      </div>
    </div>
  );
}

export default UnderConstruction;
