import React from 'react';
import './LineupList.css';


function LineupList({ lineup }) {
  return (
    <div className="lineup-list-container">
      <h2>Lineup</h2>
      <ul className="lineup-list">
        {lineup.map((player, index) => (
          <li key={index} className="lineup-list-item">
            <img src={player.photo} alt={player.name} className="player-photo" />
            <div className="player-info">
              <p className="player-name">{player.name}</p>
              <p className="player-position">{player.position}</p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default LineupList;
