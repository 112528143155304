import React from 'react';
import { Link } from 'react-router-dom';
import './Home.css';

const Home = () => {
  return (
    <div className="home-container">
      <div className="home-card">
      <div className="logo"></div>
        <h2>Βοήθα και εσύ τον Coach!</h2>
        <p className="site-concept">
        Δημιούργησε την ιδανική ενδεκάδα για τον επόμενο αγώνα του Άρη μας. 
          Συνεργάσου με άλλους φιλάθλους, ανακάλυψε τις πιο επιθυμητές ενδεκάδες από τον κόσμο μας 
          και δες πώς συγκρίνεται η επιλογή σου με την πραγματική σύνθεση της ομάδας!
        </p>
        <div className="home-buttons">
          <Link to="/login" className="home-button">Σύνδεση</Link>
          <Link to="/register" className="home-button">Εγγραφή</Link>
        </div>
      </div>
    </div>
  );
};

export default Home;
