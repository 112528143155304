import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './PlayerStatistics.css';
const API_URL = process.env.REACT_APP_API_URL;

const PlayerStatistics = () => {
  const [statistics, setStatistics] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  

  useEffect(() => {
    const fetchStatistics = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/player-statistics`);
        const sortedStatistics = response.data.statistics.sort((a, b) => b.percentage - a.percentage);
        setStatistics(sortedStatistics);
      } catch (error) {
        console.error('Error fetching statistics:', error);
        setError('Error fetching statistics');
      } finally {
        setLoading(false);
      }
    };

    fetchStatistics();
  }, []);

  if (loading) {
    return <p>Loading player statistics...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="statistics-section">
      <h2>Ποσοστό επιλογής κάθε παίκτη</h2>
      <table className="statistics-table">
        <thead>
          <tr>
            <th>Rank</th>
            <th>Player</th>
            <th>Επιλεγεται απο το </th>
          </tr>
        </thead>
        <tbody>
          {statistics.map((stat, index) => (
            <tr key={stat.player}>
              <td>{index + 1}</td>
              <td>
                <div className="player-info">
                  <img
                    src={stat.photo}
                    alt={stat.player}
                    className="player-photo"
                  />
                  {stat.player}
                </div>
              </td>
              <td>{stat.percentage}% των χρηστών</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PlayerStatistics;
