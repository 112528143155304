import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
const API_URL = process.env.REACT_APP_API_URL;


const arisPlayers = [
  { id: 1, name: 'Julián Cuesta', position: 'GK', photo: require('../images/players/cuesta.jpg'), lastGameRating: 7.2, averageRating: 6.9 },
  { id: 2, name: 'Κωνσταντίνος Κυριαζής', position: 'GK', photo: require('../images/players/kyriazis.jpg'), lastGameRating: null, averageRating: null },
  { id: 3, name: 'Filip Sidklev', position: 'GK', photo: require('../images/players/Sidklev.jpg'), lastGameRating: null, averageRating: null },
  { id: 4, name: 'Fabiano', position: 'CB', photo: require('../images/players/fabiano.jpg'), lastGameRating: null, averageRating: null },
  { id: 5, name: 'Jakub Brabec', position: 'CB', photo: require('../images/players/brabec.jpg'), lastGameRating: 6.7, averageRating: 6.8 },
  { id: 6, name: 'Fran Vélez', position: 'CB', photo: require('../images/players/velez.jpg'), lastGameRating: 6.4, averageRating: 6.7 },
  { id: 7, name: 'Lindsay Rose', position: 'CB', photo: require('../images/players/rose.jpg'), lastGameRating: null, averageRating: null },
  { id: 8, name: 'Hugo Mallo', position: 'RB', photo: require('../images/players/mallo.jpg'), lastGameRating: 7.5, averageRating: 7.2 },
  { id: 9, name: 'Martín Montoya', position: 'RB', photo: require('../images/players/montoya.jpg'), lastGameRating: 7.5, averageRating: 7.2 },
  { id: 10, name: 'Valentino Fattore', position: 'RB', photo: require('../images/players/fattore.jpg'), lastGameRating: null, averageRating: null },
  { id: 11, name: 'Martin Frydek', position: 'LB', photo: require('../images/players/frydek.jpg'), lastGameRating: 7.2, averageRating: 6.9 },
  { id: 12, name: 'Juankar', position: 'LB', photo: require('../images/players/juankar.jpg'), lastGameRating: 7.2, averageRating: 6.9 },
  { id: 13, name: 'Jean Jules', position: 'CM', photo: require('../images/players/jules.jpg'), lastGameRating: null, averageRating: null },
  { id: 14, name: 'Monchu', position: 'CM', photo: require('../images/players/monchu.jpg'), lastGameRating: 7.1, averageRating: 7.0 },
  { id: 15, name: 'José Cifuentes', position: 'CM', photo: require('../images/players/cifuentes.jpg'), lastGameRating: 7.1, averageRating: 7.0 },
  { id: 16, name: 'Vladimír Darida', position: 'CM', photo: require('../images/players/darida.jpg'), lastGameRating: 7.0, averageRating: 7.0 },
  { id: 17, name: 'Manu García', position: 'CM', photo: require('../images/players/garcia.jpg'), lastGameRating: null, averageRating: null },
  { id: 18, name: 'Γιάννης Φετφατζίδης', position: 'RW', photo: require('../images/players/fetfatzidis.jpg'), lastGameRating: 6.6, averageRating: 6.7 },
  { id: 19, name: 'Shapi Suleymanov', position: 'RW', photo: require('../images/players/suleymanov.jpg'), lastGameRating: 7.0, averageRating: 7.0 },
  { id: 20, name: 'Pione Sisto', position: 'LW', photo: require('../images/players/sisto.jpg'), lastGameRating: null, averageRating: null },
  { id: 21, name: 'Álvaro Zamora', position: 'LW', photo: require('../images/players/zamora.jpg'), lastGameRating: 7.2, averageRating: 7.0 },
  { id: 22, name: 'Kike Saverio', position: 'LW', photo: require('../images/players/saverio.jpg'), lastGameRating: null, averageRating: null },
  { id: 23, name: 'Clayton Diandy', position: 'LW', photo: require('../images/players/diandy.jpg'), lastGameRating: null, averageRating: null },
  { id: 24, name: 'Loren Morón', position: 'ST', photo: require('../images/players/moron.jpg'), lastGameRating: 6.3, averageRating: 6.5 },
  { id: 25, name: 'Θεόδωρος-Γεώργιος Αγοραστός', position: 'ST', photo: require('../images/players/agorastos.jpg'), lastGameRating: null, averageRating: null },
  { id: 26, name: 'Robin Quaison', position: 'ST', photo: require('../images/players/quaison.jpg'), lastGameRating: null, averageRating: null },
];

// Predefined list of opponents
const opponentsList = [
  'aek', 'asteras', 'atromitos', 'kalithea', 'lamia', 'levadiakos', 
  'ofi', 'olympiakos', 'panetolikos', 'panseraikos', 'pao', 'paok', 'volos'
];

function Admin() {
  const [selectedLineup, setSelectedLineup] = useState([]);
  const [matchRound, setMatchRound] = useState('');
  const [opponent, setOpponent] = useState('');
  const [matchDate, setMatchDate] = useState('');
  const [matchTime, setMatchTime] = useState('');
  const [stadium, setStadium] = useState('');
  const [currentMatchId, setCurrentMatchId] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [playerRatings, setPlayerRatings] = useState({});
  const navigate = useNavigate();

    useEffect(() => {
    const fetchCurrentMatch = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/get-current-match`);
        if (response.data.matchId) {
          setCurrentMatchId(response.data.matchId);
        }
      } catch (error) {
        console.error('Error fetching current match ID:', error);
      }
    };

    fetchCurrentMatch();
  }, []);

  const handlePlayerRatingChange = (playerId, rating) => {
    setPlayerRatings((prevRatings) => ({
      ...prevRatings,
      [playerId]: rating,
    }));
  };

  const handleSaveRatings = async () => {
    try {
      const response = await axios.post(`${API_URL}/api/update-player-ratings`, {
        matchId: currentMatchId,
        playerRatings: playerRatings,  // Make sure playerRatings is an array of objects like { playerId, newRating }
      });
  
      if (response.status === 200) {
        setSuccessMessage('Ratings saved successfully!');
      } else {
        alert('Failed to save ratings');
      }
    } catch (error) {
      console.error('Error saving ratings:', error);
      alert('Error saving ratings. Please try again.');
    }
  };
  
  const handleUpdateMatchDetails = async () => {
    try {
      const matchIdNumber = parseInt(matchRound, 10);

      if (isNaN(matchIdNumber)) {
        alert('Invalid Match ID. Please enter a valid number.');
        return;
      }

      const response = await axios.post(`${API_URL}/api/update-match`, {
        matchId: matchIdNumber,
        opponent,
        matchTime: new Date(`${matchDate}T${matchTime}`).toISOString(),
        stadium,
      });

      if (response.status === 200) {
        setSuccessMessage('Match updated successfully!');
      } else {
        alert('Failed to update match');
      }
    } catch (error) {
      console.error('Error updating match:', error);
      alert('Error updating match. Please check the console for more details.');
    }
  };

  const handlePlayerClick = (player) => {
    setSelectedLineup((prevLineup) => {
      if (prevLineup.includes(player)) {
        return prevLineup.filter((p) => p !== player);
      } else {
        if (prevLineup.length < 11) {
          return [...prevLineup, player];
        } else {
          alert('You can only select 11 players.');
          return prevLineup;
        }
      }
    });
  };

  const handleLineupUpdate = async () => {
    if (selectedLineup.length !== 11) {
      alert('Please select exactly 11 players.');
      return;
    }

    const actualLineup = selectedLineup.map(playerName => {
      const player = arisPlayers.find(p => p.name === playerName);
      return {
        player: {
          id: player.id,
          name: player.name,
          realPosition: player.position,
          photo: player.photo,
          lastGameRating: player.lastGameRating,
          averageRating: player.averageRating,
        },
        position: player.position
      };
    });

    try {
      const response = await axios.post(`${API_URL}/api/update-lineup`, {
        matchId: matchRound.toString(),
        actualLineup: actualLineup,
      });

      if (response.status === 200) {
        setSuccessMessage('Lineup updated successfully!');
      } else {
        alert('Failed to update lineup');
      }
    } catch (error) {
      console.error('Error updating lineup:', error);
      alert('There was an error updating the lineup.');
    }
  };

  const handleAwardPoints = async () => {
    const token = localStorage.getItem('token');

    if (!token) {
      alert('No token found. Please log in again.');
      return;
    }

    try {
      const response = await axios.post(`${API_URL}/api/awardpoints`, {
        matchId: currentMatchId
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        setSuccessMessage('Points awarded successfully!');
      } else {
        alert('Error awarding points.');
      }
    } catch (error) {
      console.error('Error awarding points:', error);
      alert('There was an error awarding points.');
    }
  };

  const handleResetLineups = async () => {
    try {
      const response = await axios.post(`${API_URL}/api/reset-lineups`);

      if (response.status === 200) {
        setSuccessMessage('All user lineups have been reset!');
      } else {
        alert('Error resetting lineups.');
      }
    } catch (error) {
      console.error('Error resetting lineups:', error);
      alert('There was an error resetting the lineups.');
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    navigate('/login');
  };

  return (
    <div style={{ padding: '20px', maxWidth: '600px', margin: 'auto' }}>
      <h2>Admin Panel</h2>
      
      {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}

      <div style={{ marginBottom: '20px' }}>
        <h3>Update Match Details</h3>
        <label>
          Match ID:
          <input
            type="number"
            value={matchRound}
            onChange={(e) => setMatchRound(Number(e.target.value))}
            style={{ marginLeft: '10px', padding: '5px' }}
          />
        </label>
        <br />
        <label>
          Opponent:
          <select
            value={opponent}
            onChange={(e) => setOpponent(e.target.value)}
            style={{ marginLeft: '10px', padding: '5px' }}
          >
            <option value="">Select Opponent</option>
            {opponentsList.map((team) => (
              <option key={team} value={team}>
                {team}
              </option>
            ))}
          </select>
        </label>
        <br />
        <label>
          Match Date:
          <input
            type="date"
            value={matchDate}
            onChange={(e) => setMatchDate(e.target.value)}
            style={{ marginLeft: '10px', padding: '5px' }}
          />
        </label>
        <br />
        <label>
          Match Time:
          <input
            type="time"
            value={matchTime}
            onChange={(e) => setMatchTime(e.target.value)}
            style={{ marginLeft: '10px', padding: '5px' }}
          />
        </label>
        <br />
        <label>
          Stadium:
          <input
            type="text"
            value={stadium}
            onChange={(e) => setStadium(e.target.value)}
            style={{ marginLeft: '10px', padding: '5px' }}
          />
        </label>
        <br />
        <button onClick={handleUpdateMatchDetails} style={{ padding: '8px 12px', cursor: 'pointer', marginTop: '10px' }}>
          Update Match Details
        </button>
      </div>

      <div style={{ marginBottom: '20px' }}>
        <h3>Update Actual Lineup</h3>
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
          {arisPlayers.map((player) => (
            <button
              key={player.id}
              onClick={() => handlePlayerClick(player.name)}
              style={{
                padding: '8px',
                backgroundColor: selectedLineup.includes(player.name) ? '#4CAF50' : '#f0f0f0',
                color: selectedLineup.includes(player.name) ? '#fff' : '#000',
                border: '1px solid #ddd',
                borderRadius: '4px',
                cursor: 'pointer',
              }}
            >
              {player.name.split(' ').pop()}
            </button>
          ))}
        </div>

        <div style={{ marginTop: '20px' }}>
          <p><strong>Selected Lineup:</strong> {selectedLineup.map((player) => player.split(' ').pop()).join(', ')}</p>
        </div>

        <button onClick={handleLineupUpdate} style={{ padding: '8px 12px', cursor: 'pointer', marginTop: '10px' }}>
          Update Lineup
        </button>
      </div>

      <div style={{ marginBottom: '20px' }}>
        <h3>Insert Player Ratings</h3>
        {arisPlayers.map((player) => (
          <div key={player.id} style={{ marginBottom: '10px' }}>
            <label>
              {player.name} Rating:
              <input
                type="number"
                step="0.1"
                value={playerRatings[player.id] || ''}
                onChange={(e) => handlePlayerRatingChange(player.id, e.target.value)}
                style={{ marginLeft: '10px', padding: '5px', width: '80px' }}
              />
            </label>
          </div>
        ))}
        <button onClick={handleSaveRatings} style={{ padding: '8px 12px', cursor: 'pointer', marginTop: '10px', backgroundColor: '#4CAF50', color: '#fff' }}>
          Save Ratings
        </button>
      </div>

      <div style={{ marginBottom: '20px' }}>
        <button onClick={handleAwardPoints} style={{ padding: '8px 12px', cursor: 'pointer', marginTop: '10px', backgroundColor: '#4CAF50', color: '#fff' }}>
          Award Points
        </button>
      </div>

      <div style={{ marginBottom: '20px' }}>
        <button onClick={handleResetLineups} style={{ padding: '8px 12px', cursor: 'pointer', marginTop: '10px', backgroundColor: '#f44336', color: '#fff' }}>
          Reset Lineups
        </button>
      </div>

      <div>
        <button onClick={handleLogout} style={{ padding: '8px 12px', cursor: 'pointer', backgroundColor: '#f44336', color: '#fff', border: 'none' }}>
          Logout
        </button>
      </div>
    </div>
  );
}

export default Admin;