import React, { useEffect, useState } from 'react';
import './TopLineups.css';
import LineupList from './LineupList';
//import LineupFormation from './LineupFormation'; // Make sure to create and import this component
const API_URL = process.env.REACT_APP_API_URL;

const arisPlayers = [
  { id: 1, name: 'Julián Cuesta', position: 'GK', photo: require('../images/players/cuesta.jpg'), lastGameRating: 7.2, averageRating: 6.9 },
  { id: 2, name: 'Κωνσταντίνος Κυριαζής', position: 'GK', photo: require('../images/players/kyriazis.jpg'), lastGameRating: null, averageRating: null },
  { id: 3, name: 'Filip Sidklev', position: 'GK', photo: require('../images/players/Sidklev.jpg'), lastGameRating: null, averageRating: null },
  { id: 4, name: 'Fabiano', position: 'CB', photo: require('../images/players/fabiano.jpg'), lastGameRating: null, averageRating: null },
  { id: 5, name: 'Jakub Brabec', position: 'CB', photo: require('../images/players/brabec.jpg'), lastGameRating: 6.7, averageRating: 6.8 },
  { id: 6, name: 'Fran Vélez', position: 'CB', photo: require('../images/players/velez.jpg'), lastGameRating: 6.4, averageRating: 6.7 },
  { id: 7, name: 'Lindsay Rose', position: 'CB', photo: require('../images/players/rose.jpg'), lastGameRating: null, averageRating: null },
  { id: 8, name: 'Hugo Mallo', position: 'RB', photo: require('../images/players/mallo.jpg'), lastGameRating: 7.5, averageRating: 7.2 },
  { id: 9, name: 'Martín Montoya', position: 'RB', photo: require('../images/players/montoya.jpg'), lastGameRating: 7.5, averageRating: 7.2 },
  { id: 10, name: 'Valentino Fattore', position: 'RB', photo: require('../images/players/fattore.jpg'), lastGameRating: null, averageRating: null },
  { id: 11, name: 'Martin Frydek', position: 'LB', photo: require('../images/players/frydek.jpg'), lastGameRating: 7.2, averageRating: 6.9 },
  { id: 12, name: 'Juankar', position: 'LB', photo: require('../images/players/juankar.jpg'), lastGameRating: 7.2, averageRating: 6.9 },
  { id: 13, name: 'Jean Jules', position: 'CM', photo: require('../images/players/jules.jpg'), lastGameRating: null, averageRating: null },
  { id: 14, name: 'Monchu', position: 'CM', photo: require('../images/players/monchu.jpg'), lastGameRating: 7.1, averageRating: 7.0 },
  { id: 15, name: 'José Cifuentes', position: 'CM', photo: require('../images/players/cifuentes.jpg'), lastGameRating: 7.1, averageRating: 7.0 },
  { id: 16, name: 'Vladimír Darida', position: 'CM', photo: require('../images/players/darida.jpg'), lastGameRating: 7.0, averageRating: 7.0 },
  { id: 17, name: 'Manu García', position: 'CM', photo: require('../images/players/garcia.jpg'), lastGameRating: null, averageRating: null },
  { id: 18, name: 'Γιάννης Φετφατζίδης', position: 'RW', photo: require('../images/players/fetfatzidis.jpg'), lastGameRating: 6.6, averageRating: 6.7 },
  { id: 19, name: 'Shapi Suleymanov', position: 'RW', photo: require('../images/players/suleymanov.jpg'), lastGameRating: 7.0, averageRating: 7.0 },
  { id: 20, name: 'Pione Sisto', position: 'LW', photo: require('../images/players/sisto.jpg'), lastGameRating: null, averageRating: null },
  { id: 21, name: 'Álvaro Zamora', position: 'LW', photo: require('../images/players/zamora.jpg'), lastGameRating: 7.2, averageRating: 7.0 },
  { id: 22, name: 'Kike Saverio', position: 'LW', photo: require('../images/players/saverio.jpg'), lastGameRating: null, averageRating: null },
  { id: 23, name: 'Clayton Diandy', position: 'LW', photo: require('../images/players/diandy.jpg'), lastGameRating: null, averageRating: null },
  { id: 24, name: 'Loren Morón', position: 'ST', photo: require('../images/players/moron.jpg'), lastGameRating: 6.3, averageRating: 6.5 },
  { id: 25, name: 'Θεόδωρος-Γεώργιος Αγοραστός', position: 'ST', photo: require('../images/players/agorastos.jpg'), lastGameRating: null, averageRating: null },
  { id: 26, name: 'Robin Quaison', position: 'ST', photo: require('../images/players/quaison.jpg'), lastGameRating: null, averageRating: null },
];



function TopLineups() {
  const [lineups, setLineups] = useState([]); // Top 3 lineups for the latest match
  const [allTimeLineups, setAllTimeLineups] = useState([]); // Top 3 lineups across all matches
  const [leaderboard, setLeaderboard] = useState([]); // Top users on the leaderboard
  const [opponent, setOpponent] = useState('');

  useEffect(() => {
    // Fetch the latest match ID
    fetch(`${API_URL}/api/get-last-match`)
  .then((res) => res.json())
  .then(({ matchId, opponent }) => {
    console.log("Last match ID:", matchId);
    console.log("Opponent:", opponent);
    setOpponent(opponent); // Set the opponent's name in state

    // Fetch top lineups for the latest match
    return fetch(`${API_URL}/api/top-lineups/${matchId}`);
  })
  .then((res) => res.json())
  .then((data) => {
    console.log('Top Lineups for Last Match:', data);
    setLineups(data || []);
  })
  .catch((error) => {
    console.error('Error fetching top lineups for the last match:', error);
    setLineups([]); // Default to empty array on error
  });


    // Fetch the top lineups across all matches
    fetch(`${API_URL}/api/top-lineups-all`)
      .then((res) => res.json())
      .then((data) => {
        console.log('Top Lineups Across All Matches:', data);
        setAllTimeLineups(Array.isArray(data) ? data : []); // Ensure the data is an array
      })
      .catch((error) => {
        console.error('Error fetching top lineups across all matches:', error);
        setAllTimeLineups([]); // Default to empty array on error
      });

    // Fetch the leaderboard data
    fetch(`${API_URL}/api/leaderboard`)
      .then((res) => res.json())
      .then((data) => {
        console.log('Leaderboard Data:', data);
        setLeaderboard(data.topUsers || []); // Store the leaderboard
      })
      .catch((error) => {
        console.error('Error fetching leaderboard:', error);
        setLeaderboard([]); // Default to empty array on error
      });
  }, []); // Ensure this useEffect runs only once on component mount

// Function to find a player in arisPlayers by name and return structured data
const findPlayerByName = (name) => {
  const player = arisPlayers.find((player) => player.name === name);
  if (!player) {
    console.warn(`Player not found: ${name}`);
    return null; // Return null to handle missing players gracefully
  }
  // Return a structured player object with name, position, and photo
  return {
    name: player.name,
    position: player.position,
    photo: player.photo,
  };
};


const sortPlayersByPosition = (players = []) => {
  const positionOrder = {
    GK: 1,
    LB: 2,
    CB: 3,
    RB: 4,
    CM: 5,
    LW: 6,
    RW: 7,
    ST: 8,
  };

  return players.sort((a, b) => {
    if (!a || !b) return 0; // Handle undefined players gracefully
    return positionOrder[a.position] - positionOrder[b.position];
  });
};


  const teamNamesInGreek = {
    'aek': 'ΑΕΚ',
    'paok': 'ΠΑΟΚ',
    'pao': 'Παναθηναϊκό',
    'panserraikos': 'Πανσερραϊκό',
    'atromitos': 'Ατρόμητο',
    'volos': 'Βόλο',
    'olympiakos': 'Ολυμπιακό',
    'asteras': 'Αστέρας Τρίπολης',
    'levadiakos': 'Λεβαδειακό',
    'ofi': 'ΟΦΗ',
    'kalithea': 'Καλλιθέα',
    'lamia': 'Λαμία',
    'panetolikos': 'Παναιτωλικό'
  };
  
  const feminineTeams = ['aek', 'kalithea', 'lamia'];
  const masculineTeams = ['paok', 'pao', 'panserraikos', 'atromitos', 'volos', 'olympiakos', 'asteras', 'levadiakos', 'ofi', 'panetolikos'];
  
  const getTeamPreposition = (opponent) => {
    if (feminineTeams.includes(opponent)) {
      return 'την'; // Θηλυκό άρθρο
    } else if (masculineTeams.includes(opponent)) {
      return 'τον'; // Αρσενικό άρθρο
    } else {
      return 'τον'; // Προεπιλογή: αρσενικό
    }
  };
  

  return (
    <div className="top-lineups-container">
      {/* Top Lineup for Latest Match */}
      <div className="lineups-section">
        <h2 className="section-header">Η πιο ψηφισμένη ενδεκάδα για το παιχνίδι με {getTeamPreposition(opponent)} {teamNamesInGreek[opponent]}!</h2>
        {lineups.length === 0 ? (
          <p className="no-lineup">Δεν υπάρχουν διαθέσιμες ενδεκάδες.</p>
        ) : (
          lineups.map((lineup, index) => (
            <div key={index} className="lineup-card">
              <h3 className="lineup-title">Ενδεκάδα {index + 1}</h3>
              <ul className="lineup-list">
                {sortPlayersByPosition(lineup.lineup.split(', ').map((playerName) => findPlayerByName(playerName)))
                  .map((player, i) => (
                    player ? (
                      <li key={i} className="lineup-player">
                        <img src={player.photo} alt={player.name} className="player-photo" />
                        <div className="player-info">
                          <span className="player-name">{player.name}</span>
                          <span className="player-position">({player.position})</span>
                        </div>
                      </li>
                    ) : (
                      <li key={i} className="lineup-player unknown">Άγνωστος Παίκτης</li>
                    )
                ))}
              </ul>
              <p className="lineup-users">Επιλέχθηκαν από το {lineup.percentage}% των χρηστών</p>
            </div>
          ))
        )}
      </div>
  
      {/* Top 3 All-Time Lineups */}
      <div className="lineups-section">
        <h2 className="section-header">Οι 3 πιο ψηφισμένες ενδεκάδες όλων των αγώνων!</h2>
        {allTimeLineups.length === 0 ? (
          <p className="no-lineup">Δεν υπάρχουν διαθέσιμες ενδεκάδες.</p>
        ) : (
          allTimeLineups.map((lineup, index) => (
            <div key={index} className="lineup-card">
              <h3 className="lineup-title">Ενδεκάδα {index + 1}</h3>
              <ul className="lineup-list">
                {sortPlayersByPosition(lineup.lineup.split(', ').map((playerName) => findPlayerByName(playerName)))
                  .map((player, i) => (
                    player ? (
                      <li key={i} className="lineup-player">
                        <img src={player.photo} alt={player.name} className="player-photo" />
                        <div className="player-info">
                          <span className="player-name">{player.name}</span>
                          <span className="player-position">({player.position})</span>
                        </div>
                      </li>
                    ) : (
                      <li key={i} className="lineup-player unknown">Άγνωστος Παίκτης</li>
                    )
                ))}
              </ul>
              <p className="lineup-users">Επιλέχθηκαν από το {lineup.percentage}% των χρηστών</p>
            </div>
          ))
        )}
      </div>
    </div>
  );
  
  
}

export default TopLineups;